import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"

const active = {
  title: "Kontakt"
}

const Kontakt = () => 
<DefaultLayout image="/assets/images/IMG_3303.jpg" active={active}>
  <TwoThirdsGrid textRight>
    <div>
      <HeadlineWithSub>
        <h1>Kontakt</h1>
      </HeadlineWithSub>
    </div>

    <Text>
      <p>Für Terminvereinbarungen kontaktieren sie mich bitte per Telefon oder eMail.</p>
       <p>
        <strong>Blitzbrief</strong><br />
        <a href="mailto:info@strich.sg">info@strich.sg</a>
      </p>
      <p> 
        <strong>Telefon</strong><br />
        <a href="tel:+41 79 844 04 07">079 844 04 07</a>
      </p>
      <p>
        <strong>Atelier</strong><br />
        Rorschacherstrasse 109 A,<br />
        9000 St. Gallen
      </p>
       <p>
        <a href="https://goo.gl/maps/NjSRCq4PBq4ucjoz8" target="_blank" rel="noreferrer">Maps</a>
        </p>
    </Text>
  </TwoThirdsGrid>
</DefaultLayout>

export default Kontakt